import IconRegularQuestionCircle from '@/assets/icons/solid/question-circle.svg?raw'
import IconSolidBell from '@/assets/icons/solid/bell.svg?raw'
import IconSolidSearch from '@/assets/icons/solid/search.svg?raw'
import IconSolidUser from '@/assets/icons/solid/user.svg?raw'
import IconSolidTimes from '@/assets/icons/solid/times.svg?raw'
import IconSolidStar from '@/assets/icons/solid/star.svg?raw'
import IconSolidSpinner from '@/assets/icons/solid/spinner.svg?raw'
import IconSolidHeart from '@/assets/icons/solid/heart.svg?raw'
import IconSolidCircle from '@/assets/icons/solid/circle.svg?raw'
import IconSolidPhone from '@/assets/icons/solid/phone.svg?raw'

import IconRegularShoppingBag from '@/assets/icons/regular/shopping-bag.svg?raw'
import IconRegularSearch from '@/assets/icons/regular/search.svg?raw'
import IconRegularUser from '@/assets/icons/regular/user.svg?raw'
import IconRegularHeart from '@/assets/icons/regular/heart.svg?raw'
import IconRegularTimes from '@/assets/icons/regular/times.svg?raw'
import IconRegularStar from '@/assets/icons/regular/star.svg?raw'
import IconRegularChevronDown from '@/assets/icons/regular/chevron-down.svg?raw'
import IconRegularChevronUp from '@/assets/icons/regular/chevron-up.svg?raw'
import IconRegularChevronRight from '@/assets/icons/regular/chevron-right.svg?raw'
import IconRegularChevronLeft from '@/assets/icons/regular/chevron-left.svg?raw'
import IconRegularUndoAlt from '@/assets/icons/regular/undo-alt.svg?raw'
import IconRegularAt from '@/assets/icons/regular/at.svg?raw'
import IconRegularSpinner from '@/assets/icons/regular/spinner.svg?raw'
import IconRegularTractor from '@/assets/icons/regular/tractor.svg?raw'
import IconRegularTrailer from '@/assets/icons/regular/trailer.svg?raw'
import IconRegularMinus from '@/assets/icons/regular/minus.svg?raw'
import IconRegularPlus from '@/assets/icons/regular/plus.svg?raw'
import IconRegularBars from '@/assets/icons/regular/bars.svg?raw'
import IconRegularUndo from '@/assets/icons/regular/undo.svg?raw'
import IconRegularCheck from '@/assets/icons/regular/check.svg?raw'
import IconRegularCheckSquare from '@/assets/icons/regular/check-square.svg?raw'
import IconRegularSquareO from '@/assets/icons/regular/square.svg?raw'
import IconRegularCircleSquare from '@/assets/icons/regular/check-circle.svg?raw'
import IconRegularCircle from '@/assets/icons/regular/circle.svg?raw'
import IconRegularArrowLeft from '@/assets/icons/regular/arrow-left.svg?raw'
import IconRegularArrowUp from '@/assets/icons/regular/arrow-up.svg?raw'
import IconRegularArrowDown from '@/assets/icons/regular/arrow-down.svg?raw'
import IconRegularTrashAlt from '@/assets/icons/regular/trash-alt.svg?raw'
import IconRegularPen from '@/assets/icons/regular/pen.svg?raw'
import IconRegularEdit from '@/assets/icons/regular/edit.svg?raw'
import IconRegularInfoCircle from '@/assets/icons/regular/info-circle.svg?raw'
import IconRegularHome from '@/assets/icons/regular/home.svg?raw'
import IconRegularTruck from '@/assets/icons/regular/truck.svg?raw'
import IconRegularTruckLoading from '@/assets/icons/regular/truck-loading.svg?raw'
import IconRegularClipboardCheck from '@/assets/icons/regular/clipboard-check.svg?raw'
import IconRegularBoxOpen from '@/assets/icons/regular/box-open.svg?raw'
import IconRegularForklift from '@/assets/icons/regular/forklift.svg?raw'

import IconLigthTimes from '@/assets/icons/light/times.svg?raw'
import IconLigthStar from '@/assets/icons/light/star.svg?raw'
import IconLightEnvelope from '@/assets/icons/light/envelope.svg?raw'
import IconLightPhone from '@/assets/icons/light/phone.svg?raw'
import IconLightSpinner from '@/assets/icons/light/spinner.svg?raw'
import IconLightTractor from '@/assets/icons/light/tractor.svg?raw'
import IconLightPlus from '@/assets/icons/light/plus.svg?raw'
import IconLightChevronUp from '@/assets/icons/light/chevron-up.svg?raw'
import IconLightChevronDown from '@/assets/icons/light/chevron-down.svg?raw'
import IconLightClock from '@/assets/icons/light/clock.svg?raw'
import IconLightShippingTimed from '@/assets/icons/light/shipping-timed.svg?raw'
import IconLightCheck from '@/assets/icons/light/check.svg?raw'
import IconLightMapMarker from '@/assets/icons/light/map-marker.svg?raw'
import IconLightExclamationCircle from '@/assets/icons/light/exclamation-circle.svg?raw'
import IconLightShareAlt from '@/assets/icons/light/share-alt.svg?raw'
import IconLightFrown from '@/assets/icons/light/frown.svg?raw'
import IconLightUndo from '@/assets/icons/light/undo.svg?raw'
import IconLightTag from '@/assets/icons/light/tag.svg?raw'
import IconLightRaindrops from '@/assets/icons/light/raindrops.svg?raw'
import IconLightSearch from '@/assets/icons/light/search.svg?raw'
import IconLightChevronLeft from '@/assets/icons/light/chevron-left.svg?raw'

import IconBrandInstagram from '@/assets/icons/brands/instagram.svg?raw'
import IconBrandFacebook from '@/assets/icons/brands/facebook.svg?raw'
import IconBrandTwitter from '@/assets/icons/brands/twitter.svg?raw'
import IconBrandPinterest from '@/assets/icons/brands/pinterest.svg?raw'
import IconBrandWhatsapp from '@/assets/icons/brands/whatsapp.svg?raw'
import IconBrandGoogle from '@/assets/icons/brands/google.svg?raw'
import IconBrandAmazon from '@/assets/icons/brands/amazon.svg?raw'

import IconColoredFacebook from '@/assets/icons/colored/facebook.svg?raw'
import IconColoredGoogle from '@/assets/icons/colored/google.svg?raw'
import IconColoredAmazon from '@/assets/icons/colored/amazon.svg?raw'
import IconColoredInstagram from '@/assets/icons/colored/instagram.svg?raw'
import IconColoredTiktok from '@/assets/icons/colored/tiktok.svg?raw'

import IconPaymentAmazon from '@/assets/icons/payment-methods/amazon-49-534000.svg?raw'
import IconPaymentApplePay from '@/assets/icons/payment-methods/apple-pay-1-534001.svg?raw'
import IconPaymentCirrus from '@/assets/icons/payment-methods/cirrus-1-534004.svg?raw'
import IconPaymentDiners from '@/assets/icons/payment-methods/diners-3-534008.svg?raw'
import IconPaymentDiscover from '@/assets/icons/payment-methods/discover-17-534011.svg?raw'
import IconPaymentGooglePay from '@/assets/icons/payment-methods/google-wallet-2-534014.svg?raw'
import IconPaymentJCB from '@/assets/icons/payment-methods/jcb-1-534017.svg?raw'
import IconPaymentMaestro from '@/assets/icons/payment-methods/maestro-534018.svg?raw'
import IconPaymentMastercard from '@/assets/icons/payment-methods/mastercard-17-534015.svg?raw'
import IconPaymentPayPal from '@/assets/icons/payment-methods/paypal-36-534020.svg?raw'
import IconPaymentVisa from '@/assets/icons/payment-methods/visa-21-534034.svg?raw'
import IconPaymentVisaElectron from '@/assets/icons/payment-methods/visa-22-534035.svg?raw'

export default {
  fas: {
    'question-circle': IconRegularQuestionCircle,
    bell: IconSolidBell,
    search: IconSolidSearch,
    user: IconSolidUser,
    times: IconSolidTimes,
    star: IconSolidStar,
    spinner: IconSolidSpinner,
    'chevron-down': IconRegularChevronDown,
    heart: IconSolidHeart,
    circle: IconSolidCircle,
    phone: IconSolidPhone,
  },
  far: {
    'shopping-bag': IconRegularShoppingBag,
    search: IconRegularSearch,
    user: IconRegularUser,
    heart: IconRegularHeart,
    times: IconRegularTimes,
    star: IconRegularStar,
    'chevron-down': IconRegularChevronDown,
    'chevron-up': IconRegularChevronUp,
    'chevron-left': IconRegularChevronLeft,
    'chevron-right': IconRegularChevronRight,
    'undo-alt': IconRegularUndoAlt,
    undo: IconRegularUndo,
    at: IconRegularAt,
    spinner: IconRegularSpinner,
    tractor: IconRegularTractor,
    trailer: IconRegularTrailer,
    minus: IconRegularMinus,
    plus: IconRegularPlus,
    bars: IconRegularBars,
    check: IconRegularCheck,
    'check-square': IconRegularCheckSquare,
    'square-o': IconRegularSquareO,
    circle: IconRegularCircle,
    'check-circle': IconRegularCircleSquare,
    'arrow-left': IconRegularArrowLeft,
    'arrow-up': IconRegularArrowUp,
    'arrow-down': IconRegularArrowDown,
    'trash-alt': IconRegularTrashAlt,
    pen: IconRegularPen,
    edit: IconRegularEdit,
    'info-circle': IconRegularInfoCircle,
    home: IconRegularHome,
    truck: IconRegularTruck,
    'truck-loading': IconRegularTruckLoading,
    'clipboard-check': IconRegularClipboardCheck,
    'box-open': IconRegularBoxOpen,
    forklift: IconRegularForklift,
  },
  fal: {
    times: IconLigthTimes,
    star: IconLigthStar,
    envelope: IconLightEnvelope,
    phone: IconLightPhone,
    spinner: IconLightSpinner,
    tractor: IconLightTractor,
    plus: IconLightPlus,
    clock: IconLightClock,
    check: IconLightCheck,
    'chevron-up': IconLightChevronUp,
    'chevron-down': IconLightChevronDown,
    'shipping-timed': IconLightShippingTimed,
    'map-marker': IconLightMapMarker,
    'exclamation-circle': IconLightExclamationCircle,
    'share-alt': IconLightShareAlt,
    frown: IconLightFrown,
    undo: IconLightUndo,
    tag: IconLightTag,
    raindrops: IconLightRaindrops,
    search: IconLightSearch,
    'chevron-left': IconLightChevronLeft,
  },
  fab: {
    facebook: IconBrandFacebook,
    twitter: IconBrandTwitter,
    pinterest: IconBrandPinterest,
    instagram: IconBrandInstagram,
    whatsapp: IconBrandWhatsapp,
    google: IconBrandGoogle,
    amazon: IconBrandAmazon,
  },
  clr: {
    facebook: IconColoredFacebook,
    google: IconColoredGoogle,
    amazon: IconColoredAmazon,
    instagram: IconColoredInstagram,
    tiktok: IconColoredTiktok,
  },
  pym: {
    amazon: IconPaymentAmazon,
    'apple-pay': IconPaymentApplePay,
    cirrus: IconPaymentCirrus,
    diners: IconPaymentDiners,
    discover: IconPaymentDiscover,
    'google-pay': IconPaymentGooglePay,
    jcb: IconPaymentJCB,
    maestro: IconPaymentMaestro,
    'master-card': IconPaymentMastercard,
    paypal: IconPaymentPayPal,
    visa: IconPaymentVisa,
    'visa-electron': IconPaymentVisaElectron,
  },
}
